import { useDispatch, useSelector } from 'react-redux'
import { GamePageState } from '../Store'
import { GameMode } from '../Types'
import { playTutorialMovesAction } from './PlayTutorialMoves'
import { Tutorial } from './Tutorial'
import { useCallback } from 'react'

export function useTutorial(): Tutorial | undefined {
  const gameMode = useSelector((state: GamePageState) => state.gameMode)
  const dispatch = useDispatch()
  const playNextMoves = useCallback((quantity = 1) => dispatch(playTutorialMovesAction(quantity)), [dispatch])
  const playNextMove = useCallback(() => playNextMoves(), [playNextMoves])
  const setOpponentsPlayAutomatically = useCallback((value = true) => value ? playNextMoves(Infinity) : playNextMoves(0), [playNextMoves])
  return gameMode === GameMode.TUTORIAL ? { playNextMoves, playNextMove, setOpponentsPlayAutomatically } : undefined
}