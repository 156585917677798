import { Action } from '@gamepark/rules-api'
import { GameNotification } from './GameNotification'

export type MovePlayedNotification<Move, PlayerId> = GameNotification<PlayerId> & {
  actionId: string | null
  moveView: Move
  consequences: Move[]
}

export function movePlayedNotification<Move, PlayerId>(action: Action): MovePlayedNotification<Move, PlayerId> {
  return { actionId: action.id ?? null, playerId: action.playerId, moveView: action.move, consequences: action.consequences }
}

export function isMovePlayedNotification<Move, PlayerId>(notification: GameNotification<PlayerId>): notification is MovePlayedNotification<Move, PlayerId> {
  return (notification as MovePlayedNotification<Move, PlayerId>).moveView != undefined
}
