import { gql, useQuery } from '@apollo/client'
import { User } from './User'
import { AVATAR_INFO } from '../api/Client/GameClientAPI'
import { useMemo } from 'react'

const GET_ME = gql`query Me { me { user { id name subscriptionSince avatar {...AvatarInfo} } } } ${AVATAR_INFO}`

export function useMe() {
  const fetchPolicy = useMemo(() =>
      process.env.NODE_ENV !== 'production' && !new URLSearchParams(window.location.search).get('game') ? 'cache-only' : 'cache-first'
    , [])
  return useQuery<{ me: { user: User } }>(GET_ME, { ssr: false, fetchPolicy }).data?.me
}
