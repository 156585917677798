import { PlayerTime } from '../PlayerTime'

export const GAME_OVER = 'GAME_OVER'

export interface GameOver<PlayerId = any> {
  type: typeof GAME_OVER
  players: {
    id: PlayerId
    time?: PlayerTime
    gamePointsDelta?: number
    tournamentPoints?: number
  }[]
}

export function gameOver<PlayerId>(players: { id: PlayerId, gamePointsDelta?: number, tournamentPoints?: number }[]): GameOver<PlayerId> {
  return { type: GAME_OVER, players }
}