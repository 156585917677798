import { getEnumValues } from '@gamepark/rules-api'

export enum PowerCard {
  AcidAttack = 1,
  AdrenalineAugment,
  AlienMetabolism,
  AntimatterPellets,
  ArmorPlating,
  BreakingNews,
  Camouflage,
  CannonBall,
  Dominate,
  ElectricalAura,
  Energize,
  ExtraHead,
  EyeOfTheStorm,
  Frenzy,
  GentleGiant,
  GiantBrain,
  Hibernation,
  HorsDOeuvre,
  InShape,
  InTheShadows,
  JetsScrambled,
  Kaijutsu,
  LightingSpeed,
  MadeInALab,
  MomentOfRespite,
  MonsterCoaster,
  Monumental,
  NaturalSelection,
  OperationMedia,
  Photoshoot,
  Rebooting,
  Regeneration,
  SackTheStadium,
  Scrappy,
  SeismicTest,
  SignatureMove,
  SnackTime,
  SpikedTail,
  Superconductor,
  TeslaImpulse,
  ThePartyIsOver,
  ThermonuclearCooking,
  ThunderStomp,
  TitanicBatteries,
  TooCute,
  TrendSetter,
  Unchained,
  UnstableDna,
  Unstoppable,
  UtterDestruction,
}

export const powerCards = getEnumValues(PowerCard)