export type GameOptions = {
  speed: GameSpeed
  maxSpareTime: number
  maxExceedTime: number
}

export enum GameSpeed {
  Disabled = 'Disabled',
  RealTime = 'RealTime'
}
