import { GameData, GameMode, GameNotification, GameOptions, Player, Tournament } from '../Types'

export const GAME_STATE_LOADED = 'GAME_STATE_LOADED'

export interface GameStateLoaded<Game, PlayerId> {
  type: typeof GAME_STATE_LOADED
  players: Player<PlayerId>[]
  options: GameOptions
  playerId?: PlayerId
  mode: GameMode
  state: Game
  setup: Game
  notifications: GameNotification[]
  endDate?: Date
  serverTime?: number
  tournament?: Tournament
}

export function gameStateLoaded<State, PlayerId>(data: GameData<State, PlayerId>, serverTime?: number): GameStateLoaded<State, PlayerId> {
  return {
    type: GAME_STATE_LOADED, ...data, playerId: data.myPlayerId !== null ? data.myPlayerId : undefined, serverTime, endDate: data.endDate ?? undefined,
    tournament: data.tournament ?? undefined
  }
}