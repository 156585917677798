import { RulesCreator } from '@gamepark/rules-api'
import intersection from 'lodash/intersection'
import { Store } from 'redux'
import { ANIMATION_START, animationComplete } from '../Actions'
import { GamePageState, isActionToAnimate } from '../Store'

export function createAnimationsListener<Game = any, Move = any, PlayerId = any>(
  store: Store<GamePageState<Game, Move, PlayerId>>, Rules: RulesCreator<Game, Move, PlayerId>
) {
  const timers = new Map<number, NodeJS.Timeout>()
  return () => {
    const { actions, animationPaused, players, state } = store.getState()
    if (!actions || !state) return
    const rules = new Rules(state)
    let playersToAnimateAction = players.filter(player => rules.isTurnToPlay(player.id)).map(player => player.id)
    let isAnimating = false
    for (const action of actions) {
      const animation = action.animation
      if (animation) {
        isAnimating = true
        if (action.activePlayers) {
          playersToAnimateAction = intersection(playersToAnimateAction, action.activePlayers)
        }
        const index = playersToAnimateAction.findIndex(p => p === action.playerId)
        if (index !== -1) {
          playersToAnimateAction.splice(index, 1)
        }
        if (!timers.has(animation.id)) {
          timers.set(animation.id, setTimeout(() => {
            timers.delete(animation.id)
            store.dispatch(animationComplete(animation.id))
          }, animation.duration * 1000))
        }
      } else if (!animationPaused && isActionToAnimate(action) && (!isAnimating || playersToAnimateAction.includes(action.playerId))) {
        store.dispatch({ type: ANIMATION_START, actionId: action.id })
        return
      }
    }
  }
}
