import { useDispatch, useSelector } from 'react-redux'
import { GamePageState } from '../Store'
import { MUTE_SOUNDS } from './MuteSounds'
import { UNMUTE_SOUNDS } from './UnmuteSounds'

type SoundControls = {
  mute: () => void
  unmute: () => void
  muted: boolean
}

export function useSoundControls(): SoundControls {
  const muted = useSelector((state: GamePageState) => state.soundsMuted)
  const dispatch = useDispatch()
  return {
    mute: () => {
      dispatch({ type: MUTE_SOUNDS })
      localStorage.setItem('soundsMuted', 'true')
    },
    unmute: () => {
      dispatch({ type: UNMUTE_SOUNDS })
      localStorage.removeItem('soundsMuted')
    },
    muted
  }
}
