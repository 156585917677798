import { GameSetupCreator, RulesCreator } from '@gamepark/rules-api'
import { PropsWithChildren, useMemo } from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { createAnimationsListener } from '../../animations'
import { ScoringDescription } from '../../Scoring'
import { gameMiddleware } from '../../Store/gameMiddleware'
import { gameReducer, GameReducerContext } from '../../Store/gameReducer'
import { localMovePreviewMiddleware } from '../../Store/localMovePreviewMiddleware'
import { TutorialDescription } from '../../Tutorial'
import { GameAI } from '../../Types'
import { consoleTools } from './ConsoleTools'
import { GameLocalAPI } from './GameLocalAPI'

export type LocalGameProviderProps<Game = any, GameView = any, Move = any, MoveView = any, PlayerId = any> = {
  game: string
  storage?: string
  ServerRules?: RulesCreator<Game, Move, PlayerId>
  GameSetup: GameSetupCreator<Game>
  optionsSpec?: any
  tutorial?: TutorialDescription<Game, Move, PlayerId>
  scoring?: ScoringDescription
  ai?: GameAI<Game, Move, PlayerId>
} & GameReducerContext<GameView, MoveView, PlayerId>

export function LocalGameProvider<Game = any, GameView = any, Move = any, MoveView = any, PlayerId = any>(
  { children, ...props }: PropsWithChildren<LocalGameProviderProps<Game, GameView, Move, MoveView, PlayerId>>
) {
  const store = useMemo(() => createLocalApiStore(props), [])
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

function createLocalApiStore(context: LocalGameProviderProps) {
  const api = new GameLocalAPI(context)
  const reducer = gameReducer(context)
  const store = (context.Rules as any).local ? createStore(reducer)
    : createStore(reducer, applyMiddleware(localMovePreviewMiddleware(context), gameMiddleware(api)))
  store.subscribe(createAnimationsListener(store, context.Rules))
  if (process.env.NODE_ENV === 'development') {
    window.game = consoleTools(api, store)
  }
  api.init(store.dispatch)
  return store
}
