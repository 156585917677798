export enum RuleId {
  RollDice = 1,
  PullPawn,
  GainEnergy,
  Smash,
  Buy,
  ChangePlayer,
  Effect,
  Heal,
  Dominate,
  MoveBuzzToken,
  Hibernation,
  InShape,
  MadeInALab,
  Rebooting,
  Camouflage,
  SuperConductor,
  TitanicBatteries,
  UnstableDna,
  AfterDiceResolution,
  ResolveDice,
  EndOfTurn,
  TeslaImpulse,
  PreventDamages,
  GainWhiteDiceToken,
  ThePartyIsOver,
  FreeTurn,
  OperationMedia,
  OnStartTurn,
  Alienoid,
  CyberKitty,
  Gigazaur,
  MekaDragon,
  SpacePenguin,
  TheKing,
  EffectChoice
}
