import { hasLocalMovePreview } from '@gamepark/rules-api'
import { Middleware } from 'redux'
import { ActionType } from '../Actions'
import { GamePageState } from './GamePageState'
import { GameActions, GameReducerContext, getGameAfterAnimations } from './gameReducer'

export function localMovePreviewMiddleware<Game, Move, PlayerId>(context: GameReducerContext<Game, Move, PlayerId>)
  : Middleware<{}, GamePageState<Game, Move, PlayerId>> {
  return store => next => (event: GameActions) => {
    if (event.type === ActionType.MOVE_PLAYED && !event.local) {
      const rules = getGameAfterAnimations(store.getState(), context.Rules)
      if (hasLocalMovePreview(rules) && rules.previewMove(event.move)) {
        event.local = true
      }
    }
    return next(event)
  }
}
