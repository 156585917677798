import { Action } from '@gamepark/rules-api'
import { Store } from 'redux'
import { GamePageState } from '../../Store'
import { GameSpeed } from '../../Types'
import { GameLocalAPI } from './GameLocalAPI'

declare global {
  interface Window {
    // @ts-ignore
    game: ConsoleTools
  }
}

type ConsoleTools<Game = any, Move = any, PlayerId = any> = {
  state: Game
  store: GamePageState<Game>
  moves: Action<Move, PlayerId>[]
  new: (options?: any) => void
  undo: (quantity?: number) => void
  monkeyOpponents: (start?: boolean) => void
  setPlayerName: (name: string, playerId?: PlayerId) => void
  setAnimationsSpeed: (speed?: number) => void
  tutorial: (options?: any) => void
  changePlayer: (playerId?: PlayerId) => void
  timer: (seconds: number, playerId?: PlayerId) => void
}

export function consoleTools<Game, Move, PlayerId, View, MoveView>(
  gameServerProxy: GameLocalAPI<Game, Move, PlayerId, View, MoveView>, store: Store
): ConsoleTools<Game, Move, PlayerId> {
  return {
    get state() {
      return gameServerProxy.getLocalStore().state
    },

    get store() {
      return store.getState()
    },

    get moves() {
      return gameServerProxy.getLocalStore().actions
    },

    new: (options) => {
      gameServerProxy.newGame(options)
      window.location.reload()
    },

    undo: (quantity: number = 1) => {
      gameServerProxy.undo(quantity)
    },

    monkeyOpponents: (start = true) => {
      if (start) {
        gameServerProxy.startMonkeyOpponents()
      } else {
        gameServerProxy.stopMonkeyOpponents()
      }
    },

    setPlayerName: (name: string, playerId?: PlayerId) => {
      gameServerProxy.setPlayerName(name, playerId)
      window.location.reload()
    },

    setAnimationsSpeed: (speed = 1) => {
      gameServerProxy.setAnimationsSpeed(speed)
    },

    tutorial: () => {
      gameServerProxy.newTutorial()
      window.location.reload()
    },

    changePlayer: (playerId?: PlayerId) => {
      const localStore = gameServerProxy.getLocalStore()
      localStore.playerId = playerId
      localStorage.setItem(gameServerProxy.storage, JSON.stringify(localStore))
      window.location.reload()
    },

    timer: (seconds: number, playerId?: PlayerId) => {
      const localStore = gameServerProxy.getLocalStore()
      localStore.options = {
        speed: GameSpeed.RealTime,
        maxExceedTime: 60,
        maxSpareTime: 180
      }
      if (playerId === undefined) playerId = localStore.playerId
      const player = localStore.players.find(player => player.id === playerId)!
      player.time = {
        playing: true,
        lastChange: new Date().toISOString(),
        availableTime: seconds * 1000,
        highestPlayTime: 0,
        highestDownTime: 0,
        cumulatedPlayTime: 0,
        cumulatedDownTime: 0,
        cumulatedWaitForMeTime: 0,
        weightedWaitForMeTime: 0
      }
      localStorage.setItem(gameServerProxy.storage, JSON.stringify(localStore))
      window.location.reload()
    }
  }
}