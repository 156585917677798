import {gql} from '@apollo/client'

export const CAN_REMATCH = gql`
  query Game($id: String!) {
    game(id: $id) {
      id canRematch rematch { id startDate players { name userId ready } }
    }
  }
`

export interface CanRematchData {
  id: string
  canRematch: boolean
  rematch: RematchData
}

export interface RematchData {
  id: string
  startDate: string | null
  players: RematchPlayerData[]
}

export interface RematchPlayerData {
  name: string
  userId: string
  ready: boolean
}

export const REMATCH = gql`
  mutation Rematch($gameId: String!) {
    rematch(gameId: $gameId) { id canRematch rematch { id startDate myPlayerId players { id name userId ready } } }
  }
`

export const ACCEPT_REMATCH = gql`
  mutation AcceptRematch($gameId: String!) {
    joinGame(gameId: $gameId) { id myPlayerId players { id ready } }
  }
`

export const REFUSE_REMATCH = gql`
  mutation RefuseRematch($gameId: String!) {
    leaveGameSetup(gameId: $gameId) { id myPlayerId players { id ready } }
  }
`
