import { GameNotification, Player } from '../Types'

export const GAME_NOTIFICATIONS_RECEIVED = 'GAME_NOTIFICATIONS_RECEIVED'

export interface GameNotificationsReceived<PlayerId> {
  type: typeof GAME_NOTIFICATIONS_RECEIVED
  notifications: GameNotification<PlayerId>[]
  notificationsIndex: number
  players: Player<PlayerId>[]
  serverTime?: number
}

export function gameNotificationsReceived<PlayerId>(notifications: GameNotification<PlayerId>[], notificationsIndex: number = 0, players: Player<PlayerId>[], serverTime?: number): GameNotificationsReceived<PlayerId> {
  return { type: GAME_NOTIFICATIONS_RECEIVED, notifications, notificationsIndex, players, serverTime }
}